(function ($) {
  'use strict';

  //Add Custom Code
  function countdownTimer(deadline) {
    var now = new Date().getTime();
    var t = deadline - now;

    var zone = moment.tz.zone('Europe/Vienna');
    var deadlineUtc = zone.parse(Date.UTC(2024, 1, 8, 0, 0));
    var nowUtc = zone.parse(moment.tz().utc());
    var zoneDiff = (deadlineUtc - nowUtc);
    t = t - (zoneDiff * 60 * 1000);

    if(t > 0) {
      var days = Math.floor(t / (1000 * 60 * 60 * 24));
      var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((t % (1000 * 60)) / 1000);

      document.getElementById("countdown_timer_days").innerHTML = days;
      document.getElementById("countdown_timer_hours").innerHTML = hours;
      document.getElementById("countdown_timer_minutes").innerHTML = minutes;
      document.getElementById("countdown_timer_seconds").innerHTML = seconds;
    } else {
      document.getElementById("countdown_timer_head").innerHTML = '125 JAHRE SK RAPID';
      document.getElementById("countdown_timer").className = 'phase2';
      document.getElementById("top-bar").className = document.getElementById("top-bar").className + ' phase2';
    }
  }

  function startCountdownTimer() {
    const colors = ['#00794C','#A51C24','#004F81','#FFFFFF'];
    const end = Date.now() + 4 * 1000;

    if(window.location.pathname === "/") {
      setTimeout(function() {
        (function frame() {
          confetti({
            // particleCount: 2,
            particleCount: 100,
            angle: 60,
            spread: 20,
            origin: { x: 0.65, y: 0.05 },
            colors: colors,
            scalar: 0.5,
            shapes: ["circle"],
            startVelocity: 30,
            decay: 1
          });

          confetti({
            // particleCount: 2,
            particleCount: 100,
            scalar: 0.5,
            shapes: ["circle"],
            angle: 120,
            spread: 20,
            origin: { x: 0.35, y: 0.05 },
            colors: colors,
            startVelocity: 30,
            decay: 1
          });

          if (Date.now() < end) {
            setTimeout(function() {
              requestAnimationFrame(frame);
            }, 300);
          }
        })();
      }, 2000);
    }

    var deadline = new Date("2024-01-08 00:00:00").getTime();
    var now = new Date().getTime();
    // countdownTimer(deadline);
    // var x = setInterval(function() {
    //   countdownTimer(deadline);
    // }, 1000);
    // // Output for over time
    // if (deadline - now < 0) {
    //   clearInterval(x);
    // }
  }


  $(document).ready(function () {
    // startCountdownTimer();

    // $('input[name=birthDate]').datepicker({
    //   format: 'dd.mm.yyyy'
    // });

    $('.energy-switch').click(function () {
      $(this).toggleClass('off');
      $('#top-bar-off').toggleClass('visible');
      return false;
    });

    $('.magnific-img').magnificPopup({
      type: 'image',
    });

    $('.gallery-box').magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true
      }
    });

    $('.youtube-video-link').on('click', function() {
      var videoId = $(this)[0].dataset.videoId;
      if (videoId) {
        var iframe = $('<iframe>', {
          class: 'embed-responsive-item',
          src: 'https://www.youtube.com/embed/' + videoId + '?autoplay=1',
          frameborder: 0,
          allowfullscreen: true
        });
        $(this).empty().append(iframe);
      } else {
        console.log('ERROR: video ID is missing');
      }
      return false;
    });

    var slidesCount = $('.games-home-slider > div').length;

    $('.games-home-slider').slick({
      dots: false,
      infinite: false,
      initialSlide: slidesCount > 1 ? 1 : 0,
      prevArrow: '<button type="button" class="landing-slick-prev"></button>',
      nextArrow: '<button type="button" class="landing-slick-next"></button>'
    });

    var mainSlider = $('.main-slider');
    mainSlider.slick({
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 8000,
      dots: true,
      prevArrow: '<button type="button" class="custom-slick-prev"><i class="fa fa-chevron-left"></i></button>',
      nextArrow: '<button type="button" class="custom-slick-next"><i class="fa fa-chevron-right"></i></button>',
    });

    mainSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      $('.slider-side-box').removeClass('active');
      $('.slider-side-box-' + nextSlide).addClass('active');
    });

    if (typeof window.active_slide !== 'undefined') {
      mainSlider.slick('slickGoTo', window.active_slide, true);
    }

    $('.slider-side-box').click(function () {
      if ($(this).hasClass('active')) {
        window.location.href = $(this)[0].dataset.link;
      } else {
        mainSlider.slick('slickGoTo', $(this).data('slide-number'));
      }
      return false;
    });

    $('.box-slide-holder').slick({
      dots: false,
      infinite: true,
      prevArrow: '<button type="button" class="box-slick-prev"><i class="fa fa-chevron-left"></i></button>',
      nextArrow: '<button type="button" class="box-slick-next"><i class="fa fa-chevron-right"></i></button>',
    });

    $('.shop-slick').slick({
      dots: false,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            prevArrow: '<button type="button" class="landing-slick-prev"></button>',
            nextArrow: '<button type="button" class="landing-slick-next"></button>',
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: '<button type="button" class="landing-slick-prev"></button>',
            nextArrow: '<button type="button" class="landing-slick-next"></button>',
          },
        }],
    });

    $('#season-select').change(function () {
      $('#season-form').submit();
    });

    $('#league-select').change(function () {
      var value = $(this).val();
      var rows = $('.game-list-row');

      if (value !== '') {
        rows.hide();
        $(value).show();
      } else {
        rows.show();
      }

      rows.removeClass('even').removeClass('odd');

      var i = 1;
      var className = 'odd';
      $('.game-list-row:visible').each(function () {
        if (i % 2) {
          if (className === 'even') {
            className = 'odd';
          } else {
            className = 'even';
          }
        }
        $(this).addClass(className);
        i++;
      });
    });

    /* ------Validations------ */

    // TODO: Test!!!
    $('.member-form-step-1').on('form-send-success', function (event, data) {
      var form1 = $(this);
      var form2 = $(this).closest('.member-form-steps').find('.member-form-step-2');

      var enabled = data.member.memberStatus === 'activ';
      $.each(data.member, function (key, value) {
        var isEnabled = enabled;
        if (key === 'memberNo' || key === 'lastName' || key === 'birthDate') {
          isEnabled = false;
        }
        setInputContent(key, value, isEnabled, form2);
      });

      form1.hide();
      form2.show();
    });

    $('input[name="currentClub"]').change(function () {
      if ($(this).val() === 'yes') {
        $('.club-name').show();
      } else {
        $('.club-name').hide();
      }
    });

    $('input[name="currentLAZ"]').change(function () {
      if ($(this).val() === 'yes') {
        $('.laz-name').show();
      } else {
        $('.laz-name').hide();
      }
    });
  });

  // ********************************************************

  var landingVS = $('.landing-vs');

  if (landingVS.length) {
    /*
    var currentGameId = 20;
    var diffSec = 0;
    var timerRunning = false;

    var updateTime = function (force) {
      var addZero = function (int) {
        if (int < 10) {
          return '0' + int;
        }
        return int;
      };

      if (typeof force === 'undefined') {
        force = false;
      }
      if (!timerRunning && !force) {
        return;
      }

      var duration = window.moment.duration(diffSec, 'seconds');
      var diffString = addZero((duration.hours() * 60) + duration.minutes()) + ':' + addZero(duration.seconds());
      if (diffSec <= 0) {
        diffString = '00:00';
      } else {
        diffSec += 1;
      }

      $('.games-box-game-' + currentGameId + ' .game-vs-center-time').html(diffString);
    };

    setInterval(function () {
      updateTime();
    }, 1000);
    */

    var currentTime = window.moment().unix();
    var interval = 1000;

    $('.landing-countdown').each(function () {
      var countdownContainer = $(this).find('.countdown-container');
      var eventTime = countdownContainer.data('event-time');
      var leftTime = eventTime - currentTime;
      var duration = window.moment.duration(leftTime, 'seconds');
      if (leftTime > 0) {
        setInterval(function () {
          if (duration.asSeconds() <= 0) {
            window.location.reload();
          }
          duration = window.moment.duration(duration.asSeconds() - 1, 'seconds');

          var remainingDays = (duration.asDays() >= 1) ? Math.floor(duration.asDays()) + ((Math.floor(duration.asDays()) > 1) ? ' ' + window.landingTrans.days : ' ' + window.landingTrans.day) + '<br />' : '';
          var remainingHours = (duration.hours() >= 1) ? duration.hours() + ((duration.hours() > 1) ? ' ' + window.landingTrans.hours : ' ' + window.landingTrans.hour) + ' ' : '';
          var remainingMinutes = (duration.hours() >= 1) ? duration.minutes() + ((duration.minutes() > 1) ? ' ' + window.landingTrans.minutes : ' ' + window.landingTrans.minute) + ' ' : '';
          var remainingSeconds = (duration.asSeconds() > 0) ? duration.seconds() + ' ' + window.landingTrans.seconds : '';

          countdownContainer.html(
            remainingDays +
            remainingHours +
            remainingMinutes +
            remainingSeconds
          );
        }, interval);
      } else {
        $(this).hide();
      }
    });

  }

  // ********************************************************

  $('.countdown-js-content').each(function () {
    $(this).countdown($(this).data('date'), function (event) {
      $(this).html(event.strftime('<div class="col">%D</div><div class="col">%H</div><div class="col">%M</div><div class="col">%S</div>'));
    });
  });

  $.fn.rapidValidate = function (rules, successHolderSelector, compareObject, callback, type) {
    $(this).prepend('<ul class="form-errors"></ul>');

    $(this).validate({
      rules: rules,
      submitHandler: function (form) {
        var location = $(form).attr('action');
        var data = $(form).serializeArray();
        if (type === 'member-form') {
          var onlineBillFound = false;
          $.each(data, function (key, value) {
            if (value.name === 'onlineBill') {
              onlineBillFound = true;
            }
          });
          if (!onlineBillFound) {
            data.push({ onlineBill: false });
          }
        }
        var sendData = data;

        if (typeof compareObject !== 'undefined') {
          sendData = [];
          $.each(data, function (key, value) {
            if (value.value === '') {
              value.value = null;
            }
            if (typeof compareObject[value.name] === 'undefined' || compareObject[value.name] !== value.value) {
              sendData.push(value);
            }
          });
        }

        $.ajax({
          type: 'POST',
          url: location,
          data: sendData,
          success: function (json) {
            if (json.success) {
              $(form).hide();
              $(successHolderSelector).show();
            } else {
              var errors = json.messages;
              var holder = $(form).find('.form-errors');
              holder.html('');

              $.each(errors, function (key, value) {
                holder.append('<li>' + value + '</li>');
              });
            }

            if (typeof callback !== 'undefined') {
              callback(json);
            }
          },
          dataType: 'json',
        });
      },
      highlight: function (element) {
        $(element).closest('.form-group').addClass('has-error');
      },
      unhighlight: function (element) {
        $(element).closest('.form-group').removeClass('has-error');
      },
      errorElement: 'span',
      errorClass: 'help-inline text-danger',
      errorPlacement: function (error, element) {
        if (element.attr('type') === 'checkbox' || element.attr('type') === 'radio') {
          element.closest('.form-group').append(error);
        } else {
          error.insertAfter(element);
        }
      },
    });
  };

  function setInputContent(name, value, enabled, form) {
    var element = form.find('input[name="' + name + '"], select[name="' + name + '"]');
    if (element.length === 0) {
      return;
    }
    if (value === null) {
      value = '';
    }

    if (enabled) {
      if (element.attr('type') === 'checkbox') {
        if (value) {
          element.prop('checked', true);
        } else {
          element.prop('checked', false);
        }
      } else {
        element.val(value);
        $('#form-text-' + name).remove();
        element.show();
      }
    } else {
      if (element.attr('type') === 'checkbox') {
        element.prop('disabled', true);
      } else {
        element.val(value);
        element.before('<div class="form-text form-control" id="form-text-' + name + '">' + value + '</div>');
        element.hide();
      }
    }
  }

  $(document).ready(function () {
    $('.survey-holder-js').each(function () {
      var $self = $(this);
      var showResult = function() {
        $self.find('.survey-form-holder').hide();
        $self.find('.survey-result').removeClass('d-none');
      };

      $(this).find('.survey-result-btn').click(function () {
        showResult();
        return false;
      });

      var $form = $('.vote-form');

      $form.submit(function () {
        $.ajax({
          method: $form.attr('method'),
          url: $form.attr('action'),
          data: {
            id: $('.survey-radio:checked').val(),
            surveyQuestId: $form.find('[name="surveyQuestId"]').val(),
          },
          success: function (json) {
            showResult();
            if (json.success) {
              var holder = $('.survey-result');
              var answers = json.result.answers;
              var totalVotes = 0;
              $.each(answers, function (key, answer) {
                var answerHolder = holder.find('.survey-answer-' + answer.id);
                answerHolder.find('.percent').html(answer.percent);
                answerHolder.find('.progress-bar').css('width', answer.percent + '%');
                totalVotes += answer.votes;
              });
              $('.total-votes').html(totalVotes);
              $('.voting-success').show();
            } else {
              $('.voting-error').show();
            }
          },
        });
        return false;
      });
    });
  });



  var splitArray = function (arr, size) {
    var arr2 = arr.slice(0);
    var arrays = [];

    while (arr2.length > 0) {
      arrays.push(arr2.splice(0, size));
    }

    return arrays;
  };

  function pollInstagram(id) {
    $.ajax({
      method: "GET",
      url: 'https://api.instagram.com/v1/users/623604516/media/recent/?client_id=ad63b0a3ce2948e7be6328c3a3e5bd74',
      dataType: "jsonp",
      jsonp: "callback",
      jsonpCallback: "jsonpcallback",
      success: function(json) {

        var holder = $('#instagram-'+id);
        var arrays = splitArray(json.data, 2);

        $.each(arrays, function(key, data) {
          $.each(data, function(key, value) {
            var time = moment.unix(value.created_time).fromNow(true);

            var video = '';

            if(value.type === 'video') {
              video = '<div class="instagram-video"><i class="fa fa-play-circle-o"></i></div>';
            }

            holder.append(
              '<div class="instagram-box">' +
              '<div class="row">' +
              '<div class="col-xs-13">' +
              '<div class="instagram-title">' + value.user.full_name + '</div>' +
              '</div>' +
              '<div class="col-xs-7">' +
              '<div class="instagram-time">' + time + '</div>' +
              '</div>' +
              '</div>' +
              '<div class="instagram-text">' + value.caption.text + '</div>' +
              '<div class="instagram-image"><a href="' + value.link + '" target="_blank"><img src="' + value.images.standard_resolution.url + '" class="img-responsive" />' + video + '</a></div>' +
              '</div>'
            );
          });
        });

      },
      error: function(jqXHR, textStatus, errorThrown) {
      }
    });
  }


  if($('.instagram-holder').length){
    pollInstagram($('.instagram-holder').data('id'));
  }
}(jQuery));
